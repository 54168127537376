import {createGtm} from '@gtm-support/vue-gtm';

export default defineNuxtPlugin((nuxtApp) => {
    // const config = useRuntimeConfig()
    nuxtApp.vueApp.use(
        createGtm({
            id: import.meta.env.VITE_GTM_ID,
            defer: true,
            compatibility: true,
            nonce: '2726c7f26c',
            enabled: true,
            debug: false,
            loadScript: true,
            trackOnNextTick: false,
        }),
    )
})
