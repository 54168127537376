import revive_payload_client_CapMu5pmxh from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@5.0.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CLshpFXMGZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@5.0.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZrfyoNa58u from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@5.0.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_C5ScmjBCnr from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@1.6.7_@nuxt+devtools@1.0.4_@vue+compiler-core@3.3.10_nuxt@3.8.2_postcss@8.4._234y5zyjbs67u2bmpvdfh27pvu/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_4FWWbFnstt from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@5.0.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_NXhzlGHCSW from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_vue@3.3.10/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_2itSFtsTIM from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@5.0.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_3oJkEv196c from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@1.6.7_@nuxt+devtools@1.0.4_@vue+compiler-core@3.3.10_nuxt@3.8.2_postcss@8.4._234y5zyjbs67u2bmpvdfh27pvu/node_modules/nuxt-site-config/dist/runtime/plugins/i18n.mjs";
import composition_UmaQYrXaDn from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.7_vue-router@4.2.5_vue@3.3.10/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_YNmKeUxxGu from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.7_vue-router@4.2.5_vue@3.3.10/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_SU6Qcbov64 from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@5.0.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_UMtJvHwFt1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@5.0.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import gtm_client_OzmBuHLRIb from "/vercel/path0/plugins/gtm.client.ts";
export default [
  revive_payload_client_CapMu5pmxh,
  unhead_CLshpFXMGZ,
  router_ZrfyoNa58u,
  _0_siteConfig_C5ScmjBCnr,
  payload_client_4FWWbFnstt,
  plugin_vue3_NXhzlGHCSW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_2itSFtsTIM,
  i18n_3oJkEv196c,
  composition_UmaQYrXaDn,
  i18n_YNmKeUxxGu,
  chunk_reload_client_SU6Qcbov64,
  check_outdated_build_client_UMtJvHwFt1,
  gtm_client_OzmBuHLRIb
]